import { plantin, plantinBoldItalic } from '../../styles'
import styled from '@emotion/styled'

export default styled.ul({
  marginBottom: '2rem',
  fontFamily: plantin,
  gridRowGap: '.25rem',
  textAlign: 'center',
  '& em': {
    fontFamily: plantinBoldItalic,
  },
  '@media screen and (min-width: 720px)': {
    gridColumnGap: '1rem',
    gridTemplateColumns: '1fr 1fr',
    display: 'grid',
  },
})
