import React from 'react'
import PageTitle from '../components/PageTitle'
import { Elements } from 'react-stripe-elements'

import DinnerOrderForm from '../components/DinnerOrderForm'
import formItems from '../data/forms/christmas'
import { postChristmasOrder } from '../utils/api'
import { plantin, plantinBoldItalic, plantinItalic } from '../styles'
import OrderList from '../components/OrderList'
import Layout from '../components/layout'
import styled from '@emotion/styled'
import ChristmasSoldOut from './christmas-sold-out'

const orderItems = {
  entrees: [
    'Prime Rib of Beef slow roasted for 18 hours to medium rare with horseradish cream and bordelaise',
    'Roulade of Pork Loin with plum and brioche sage stuffing, slow roasted and served with port wine sauce',
    'Honey Roasted Turkey Breast with an apricot gastrique',
  ],
  salads: [
    'Magnolias Local Caesar, toasted croutons, grana padano',
    'Spiced Pear, Endless Summer Harvest greens, candied pecans, cranberries, pickled red onions, cranberry vinaigrette',
  ],
  desserts: [
    'Cherry Pie with a local honey vanilla glaze',
    'Brandied Cherry and White Chocolate Bread Pudding with butterscotch sauce',
  ],
  sides: [
    'Bourbon Maple Glazed Carrots',
    'Haricot Verts Almondine',
    'Au Gratin Potatoes',
  ],
}

const OrderFormCopy = styled.p({
  fontFamily: plantin,
  marginTop: 0,
  maxWidth: '70ch',
  margin: '0 auto',
  '& em': {
    fontFamily: plantinBoldItalic,
  },
})

const reducer = (accumulator, currentValue) => {
  accumulator[currentValue.name] = currentValue.price ? 0 : ''
  return accumulator
}

const christmasInitialState = {
  name: '',
  email: '',
  phone: '',
  dinnerType: 'primeRib',
  salad: 'caesar',
  dessert: 'breadPudding',
  requests: '',
}

const MenuList = styled.div`
  @media screen and (min-width: 720px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
`
const MenuCat = styled.div`
  margin: 1rem 0;
`
const MenuItem = styled.div`
  /* font-weight: 700; */
  margin: 1rem 0;
`

const getTotal = () => 225

const ChristmasOrderPage = () => (<ChristmasSoldOut></ChristmasSoldOut>
//   <Layout>
//     <PageTitle header="Christmas Dinner By Magnolias" />
//     <Elements>
//       <DinnerOrderForm
//         initialState={christmasInitialState}
//         orderItems={orderItems}
//         formItems={formItems}
//         totalFunc={getTotal}
//         thankYouLink={'/christmas-thank-you/'}
//         postOrderApi={postChristmasOrder}
//       >
//         <OrderFormCopy>
//           Santa will be busy stuffing stockings, let our Executive Chef and his
//           elves prepare your Christmas feast for six guests!&nbsp;
//           <em>
//             Pick-up December 23
//             <sup>rd</sup> or before 8 PM Christmas Eve
//           </em>
//           &nbsp;and all will be merry and bright around the table! For $225 each
//           order includes:
//           <MenuList>
//             <MenuCat>
//               <h3>Choice of Centerpiece</h3>
//               {orderItems.entrees.map(e => (
//                 <MenuItem>•{e}</MenuItem>
//               ))}
//             </MenuCat>
//             <MenuCat>
//               <h3>Choice of Salad</h3>
//               {orderItems.salads.map(e => (
//                 <MenuItem>•{e}</MenuItem>
//               ))}
//             </MenuCat>
//             <MenuCat>
//               <h3>Choice of Dessert</h3>
//               {orderItems.desserts.map(e => (
//                 <MenuItem>•{e}</MenuItem>
//               ))}
//             </MenuCat>
//             <MenuCat>
//               <h3>Sides Included With Each Order</h3>
//               {orderItems.sides.map(e => (
//                 <MenuItem>•{e}</MenuItem>
//               ))}
//             </MenuCat>
//           </MenuList>
//         </OrderFormCopy>
//       </DinnerOrderForm>
//     </Elements>
//   </Layout>
)

export default ChristmasOrderPage
